<template>
  <div class="container">
    <img src="@/assets/Organibook.svg" alt="Logo" class="logo">
    <h1 class="title">Em breve, sua estante favorita</h1>
    <p class="info">
      Estamos trabalhando duro para trazer a você uma plataforma incrível onde você poderá catalogar seus livros de forma intuitiva e prática.
    </p>
    <NewsletterForms /> <!-- Adicione o componente NewsletterForms aqui -->
  </div>
</template>

<script>
// Importe o componente NewsletterForms
// import NewsletterForms from '@/components/NewsletterForm.vue';

// export default {
//   name: 'App',
//   components: {
//     NewsletterForms // Registre o componente no objeto components
//   }
// };
</script>

<style src="./assets/styles.css"></style>

